/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import {
	CreateNewServiceWithUploadToolThunk,
	setCreateNewServiceDefaultLocationThunk,
	setCreateNewServiceMaxPriceThunk,
	setCreateNewServiceMinPriceThunk,
	setCreateNewServiceOtherSubTypeThunk,
	setCreateNewServiceProviderNameThunk,
	setCreateNewServiceShowPriceThunk,
	setCreateNewServiceSubTypeThunk,
	setCreateNewServiceTypeThunk,
	setCreateNewServiceUploadImagesThunk,
} from "../../../redux-magic/store";
import location_data from "../../../redux-magic/sub-state-data/location-lat-lng";

/* Component Imports */

import ServiceCard from "../../cards/ServiceCardPublishPreview/ServiceCardPublishPreview";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import ServiceDetails from "./sub-components/CreateServiceSubComponents/ServiceDetails";
import ServiceLocation from "./sub-components/CreateServiceSubComponents/ServiceLocation";
import UploadImages from "./sub-components/CreateServiceSubComponents/UploadImages";

/* mui imports */

import {
	Alert,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";

/* Icon Imports */

import { ArrowBack, Close, InfoOutlined } from "@mui/icons-material";
import CapitalizeFirstLetter from "../../../lib/CapitalizeFirstLetter";
import { DbUserPage } from "../../../redux-magic/sub-interfaces/user-interface";

/* Styled Components */

const CustomDialog = styled(Dialog)(({ theme }) => ({
	padding: "2rem",
	[theme.breakpoints.down("sm")]: {
		padding: "1rem",
	},
	"& .MuiDialog-paper": {
		[theme.breakpoints.down("sm")]: {
			margin: "0rem",
			width: "100%",
		},
	},
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	fontSize: "1.25rem",
	fontWeight: 500,
	lineHeight: "106%",
	background: theme.palette.background.paper,
	gap: "1rem",
	padding: "1rem 2rem 0rem 2rem",
	borderRadius: "1rem 1rem 0rem 0rem",
	[theme.breakpoints.down("sm")]: {
		padding: "1rem 1rem 0rem 1rem",
	},
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1.5rem",
	padding: "2rem 2rem 2rem 2rem",
	background: theme.palette.background.paper,
	borderRadius: "0rem 0rem 1rem 1rem",
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
		padding: "1rem ",
	},
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.25rem",
}));

const CustomDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "1rem 0rem 0rem 0rem",
}));

const ServiceNameContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "2rem",
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
	fontSize: "0.75rem",
	fontWeight: 400,
	color: theme.palette.mode == "dark" ? "#212121" : "#ffffff",
	padding: "0rem",
}));

const StepDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "2.5rem",
	background: theme.palette.mode == "dark" ? "#FFFFFF" : "#212121",
	padding: "0rem 0.5rem",
	gap: "0.5rem",
	height: "1.5rem",
	width: "2.25rem",
}));

const DotContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	gap: "0.5rem",
}));

const Circle = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn": {
		"0%": {
			opacity: 0,
			// content: `${text[0]}`,
		},
		"25%": {
			opacity: 1,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn 2s infinite",
}));

const Circle1 = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn1": {
		"0%": {
			opacity: 0,
			// content: `${text[2]}`,
		},
		"50%": {
			opacity: 1,
			// content: `${text[0]}`,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn1 2s infinite",
}));

const Circle2 = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn2": {
		"0%": {
			opacity: 0,
			// content: `${text[0]}`,
		},

		"75%": {
			opacity: 1,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn2 2s infinite",
}));

const ColorLinearProgress = styled(LinearProgress)(({ theme }) => ({
	"&.MuiLinearProgress-colorPrimary": {
		backgroundColor: "#E0E0E0",
	},
	"& .MuiLinearProgress-bar": {
		backgroundColor: theme.palette.primary.main,
	},
}));

const Snack = styled(Snackbar)(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		bottom: "5rem",
		borderRadius: "0.5rem",
	},
}));

const CreateServiceWithUploadTool = ({
	open,
	handleClose,
	dispatch,
	new_listing_url,
	session,
	newServiceData,
}: {
	open: boolean;
	handleClose: any;
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	is_business_profile?: boolean;
	session: any;
	newServiceData: StateInterface["new_service_data"];
}) => {
	const theme = useTheme();

	/* User business profiles */

	let businessProfiles: DbUserPage = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.pages
					? session.user.dbUser.pages[0]
					: null
				: null
			: null
		: null;

	const businessProfilesCount = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.business_profiles_count
					? session.user.dbUser.business_profiles_count
					: 0
				: 0
			: 0
		: 0;

	const [activeStep, setActiveStep] = React.useState(0);

	const [serviceTitle, setServiceTitle] = React.useState<string>("");

	const [businessProfileName, setBusinessProfileName] = React.useState<string>("");

	const [businessProfileType, setBusinessProfileType] = React.useState<string>("");

	const [createBusinessProfile, setcreateBusinessProfile] = React.useState<boolean>(false);

	const [loading, setLoading] = React.useState(false);

	const [newListing, setNewListing] = React.useState<boolean>(false);

	const [loadNext, setLoadNext] = React.useState<boolean>(false);

	const [alertModal, setAlertModal] = React.useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const [imgPreview, setImgPreview] = React.useState<string>("");

	const [serviceNameError, setServiceNameError] = React.useState<boolean>(false);

	const [serviceProviderNameError, setServiceProviderNameError] = React.useState<boolean>(false);

	const [businessProfileNameError, setBusinessProfileNameError] = React.useState<boolean>(false);

	const [businessProfileTypeError, setBusinessProfileTypeError] = React.useState<boolean>(false);

	const [serviceTypeError, setServiceTypeError] = React.useState<boolean>(false);

	const [serviceSubTypeError, setServiceSubTypeError] = React.useState<boolean>(false);

	const [serviceMinPriceError, setServiceMinPriceError] = React.useState<boolean>(false);

	const [serviceMaxPriceError, setServiceMaxPriceError] = React.useState<boolean>(false);

	// lets select the image with priority 0 if 0 not existing then select the first image
	React.useEffect(() => {
		if (newServiceData?.images.length > 0) {
			const image = newServiceData.images.find((img) => img.priority === 0);
			if (image) {
				setImgPreview(image.caption);
			} else {
				setImgPreview(newServiceData.images[0].caption);
			}
		}
	}, [newServiceData?.images]);

	// lets check if the 0th step is complete
	const stepZeroComplete: boolean =
		(activeStep != 0 && createBusinessProfile && (businessProfileName === "" || businessProfileType === "")) ||
		(businessProfiles === null && (businessProfileName === "" || businessProfileType === "")) ||
		serviceTitle === "" ||
		loading;

	// lets check if the 1st step is complete
	// Extract current location values
	const currentLatitude = newServiceData?.search_metrics.location_coordinates[0];
	const currentLongitude = newServiceData?.search_metrics.location_coordinates[1];

	// Extract previous location values (assuming location_data is available in scope)
	const previousLatitude = location_data.location[0];
	const previousLongitude = location_data.location[1];

	// Check if location value has changed

	const didLocationChange = currentLatitude !== previousLatitude || currentLongitude !== previousLongitude;

	const stepOneComplete: boolean = activeStep === 1 && !didLocationChange;

	// lets check if the 2nd step is complete

	const stepTwoComplete: boolean =
		activeStep === 2 &&
		(newServiceData.service_type.length === 0 ||
			newServiceData.service_subtype.length === 0 ||
			(newServiceData.service_subtype.includes("other") && newServiceData.other_service_subtype === "") ||
			(newServiceData.price.show_price &&
				(newServiceData.price.min === 0 || newServiceData.price.min === null) &&
				(newServiceData.price.max === 0 || newServiceData.price.max === null)));

	// lets check if the 3rd step is complete

	const stepThreeCompelte: boolean = activeStep === 3 && newServiceData?.images.length === 0;

	// lets create functions to handle the stepper
	const handleNext = () => {
		if (activeStep === 0) {
			if (
				(createBusinessProfile && (businessProfileName === "" || businessProfileType === "")) ||
				(businessProfiles === null && (businessProfileName === "" || businessProfileType === "")) ||
				serviceTitle === ""
			) {
				if (createBusinessProfile && businessProfileName === "") {
					setBusinessProfileNameError(true);
				}
				if (createBusinessProfile && businessProfileType === "") {
					setBusinessProfileTypeError(true);
				}
				if (serviceTitle === "") {
					setServiceNameError(true);
				}
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 1) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else if (activeStep === 2) {
			if (
				newServiceData.service_provider.name === "" ||
				newServiceData.service_type.length === 0 ||
				newServiceData.service_subtype.length === 0 ||
				(newServiceData.service_subtype.includes("other") && newServiceData.other_service_subtype === "") ||
				(newServiceData.price.show_price &&
					(newServiceData.price.min === 0 || newServiceData.price.min === null) &&
					(newServiceData.price.max === 0 || newServiceData.price.max === null))
			) {
				if (newServiceData.service_provider.name === "") {
					setServiceProviderNameError(true);
				}
				if (newServiceData.service_type.length === 0) {
					setServiceTypeError(true);
				}
				if (newServiceData.service_subtype.length === 0) {
					setServiceSubTypeError(true);
				}
				if (newServiceData.service_subtype.includes("other") && newServiceData.other_service_subtype === "") {
					setServiceSubTypeError(true);
				}
				if (
					newServiceData.price.show_price &&
					(newServiceData.price.min === 0 || newServiceData.price.min === null) &&
					(newServiceData.price.max === 0 || newServiceData.price.max === null)
				) {
					if (newServiceData.price.min === 0 || newServiceData.price.min === null) {
						setServiceMinPriceError(true);
					}
					if (newServiceData.price.max === 0 || newServiceData.price.max === null) {
						setServiceMaxPriceError(true);
					}
				}
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	/* Create new service function */

	const handleClickCreateNewService = async () => {
		setLoading(true);
		setNewListing(true), setLoadNext(true);
		await dispatch(
			CreateNewServiceWithUploadToolThunk({
				createNewBusinessProfile: createBusinessProfile,
				businessProfileId: createBusinessProfile ? "" : businessProfiles?.page_id,
				businessProfileName: createBusinessProfile ? businessProfileName : businessProfiles.page_title,
				businessProfileType: createBusinessProfile ? businessProfileType.toLowerCase() : businessProfiles.page_type,
				userId: session.user.dbUser._id,
				phoneNumber: session.user.dbUser.phone,
				countryCode: session.user.dbUser.country_code,
				serviceName: serviceTitle,
				serviceLocationName: newServiceData?.location.title,
				serviceLocationCoordinates: newServiceData?.search_metrics.location_coordinates,
				servicProviderName: newServiceData?.service_provider.name,
				serviceType: newServiceData?.service_type,
				serviceSubType: newServiceData?.service_subtype,
				serviceMinPrice: newServiceData?.price.min,
				serviceMaxPrice: newServiceData?.price.max,
				serviceImages: newServiceData?.images,
			}),
		);
		// if (newServiceData.currentPage === "navbar") {
		// 	const businessType: string = createBusinessProfile
		// 		? businessProfileType.toLowerCase()
		// 		: businessProfiles?.page_type;
		// 	window.open(`/${businessType}s/${new_listing_url}`, "_self");
		// } else {
		handleModalClose();
		// }
	};

	React.useEffect(() => {
		const businessType: string = createBusinessProfile
			? businessProfileType.toLowerCase()
			: businessProfiles?.page_type;
		if (
			new_listing_url !== undefined &&
			new_listing_url !== "" &&
			newListing &&
			newServiceData.currentPage === "navbar"
		) {
			window.open(`/${businessType}s/${new_listing_url}`, "_self");
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [new_listing_url]);

	React.useEffect(() => {
		if (newServiceData?.snack_value) {
			setOpenSnackbar(true);
		}
	}, [newServiceData?.snack_value]);

	React.useEffect(() => {
		const businessProfilesCount = session
			? session.user
				? session.user.dbUser
					? session.user.dbUser.business_profiles_count
						? session.user.dbUser.business_profiles_count
						: 0
					: 0
				: 0
			: 0;

		if (businessProfilesCount === 0) {
			setcreateBusinessProfile(true);
		} else {
			setcreateBusinessProfile(false);
		}
	}, [session]);

	const handleModalClose = () => {
		setcreateBusinessProfile(false);
		setBusinessProfileName("");
		setBusinessProfileType("");
		setServiceTitle("");
		setLoading(false);
		handleClose();
		setActiveStep(0);
		dispatch(
			setCreateNewServiceDefaultLocationThunk({
				location_coordinates: location_data.location,
				address: "",
			}),
		);
		dispatch(setCreateNewServiceTypeThunk(""));
		dispatch(setCreateNewServiceSubTypeThunk([]));
		dispatch(setCreateNewServiceOtherSubTypeThunk(""));
		dispatch(setCreateNewServiceShowPriceThunk(false));
		dispatch(setCreateNewServiceMinPriceThunk(0));
		dispatch(setCreateNewServiceMaxPriceThunk(0));
		dispatch(setCreateNewServiceProviderNameThunk(""));
		dispatch(setCreateNewServiceUploadImagesThunk([]));
		setAlertModal(false);
	};

	const stepperContent = (step: number) => {
		switch (step) {
			case 0:
				return (
					<CustomDiv sx={{ flexDirection: "column", gap: "1rem", paddingTop: "2rem" }}>
						<ServiceNameContainer>
							{businessProfiles === undefined ? (
								<TextContainer sx={{ gap: "0rem" }}>
									<TextContainer sx={{ flexDirection: "row", justifyContent: "flrx-start", alignItems: "center" }}>
										<Typography variant="body1">
											Let&apos;s create a new business profile to post your property{" "}
										</Typography>
										<Tooltip title="Once created, you cannot change the property name">
											<InfoOutlined
												sx={{
													color: theme.palette.mode == "dark" ? "#FFFFFF" : "#0000008A",
													height: "1.25rem",
													width: "1.25rem",
												}}
											/>
										</Tooltip>
									</TextContainer>
									<TextField
										label="Business Name*"
										required={true}
										disabled={loading}
										value={businessProfileName}
										autoComplete="off"
										variant="outlined"
										onChange={(event: any) => {
											setBusinessProfileName(event.target.value);
											setBusinessProfileNameError(false);
										}}
										sx={{
											width: "100%",
											marginTop: "1.5rem",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										error={businessProfileNameError}
										helperText={businessProfileNameError ? "Business name is required" : ""}
									/>

									<FormControl
										variant="outlined"
										fullWidth
										sx={{
											marginTop: "1rem",
											borderRadius: "0.75rem",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										error={businessProfileTypeError}
									>
										<InputLabel
											id="profile-type"
											sx={{ padding: "0rem" }}
										>
											Business profile type*
										</InputLabel>
										<Select
											labelId="profile-type"
											label="Business profile type*"
											required={true}
											disabled={loading}
											value={businessProfileType}
											sx={{
												width: "100%",
												borderRadius: "0.75rem",
												"& .MuiOutlinedInput-root": {
													borderRadius: "0.75rem",
												},
											}}
											onChange={(e) => {
												setBusinessProfileType(e.target.value);
												setBusinessProfileTypeError(false);
											}}
										>
											<MenuItem value="agent">Agent</MenuItem>
											<MenuItem value="developer">Developer</MenuItem>
											<MenuItem value="landowner">Landowner</MenuItem>
											<MenuItem value="professional">Service Professional</MenuItem>
										</Select>
										<FormHelperText>
											{businessProfileTypeError ? "Business profile type is required" : ""}
										</FormHelperText>
									</FormControl>
								</TextContainer>
							) : null}

							<TextContainer sx={{ gap: "1rem" }}>
								<TextContainer sx={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
									<Typography sx={{ fontSize: "1rem", fontWeight: 400, lineHeight: "150%" }}>
										Enter the name of your service
									</Typography>
									<Tooltip title="Once created, you cannot change the service name">
										<InfoOutlined
											sx={{
												color: theme.palette.mode == "dark" ? "#FFFFFF" : "#0000008A",
												height: "1.25rem",
												width: "1.25rem",
											}}
										/>
									</Tooltip>
								</TextContainer>
								<TextField
									label="Service Name*"
									placeholder="Enter service name"
									required={true}
									disabled={
										loading ||
										(businessProfiles === undefined && (businessProfileName === "" || businessProfileType === ""))
									}
									value={serviceTitle}
									autoComplete="off"
									variant="outlined"
									onChange={(event: any) => {
										setServiceTitle(event.target.value);
										setServiceNameError(false);
									}}
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									error={serviceNameError}
									helperText={serviceNameError ? "Service name is required" : ""}
								/>
								{businessProfiles === undefined ? null : (
									<Typography
										sx={{
											fontSize: "1rem",
											fontWeight: 400,
											lineHeight: "150%",
										}}
									>
										Posting as :{" "}
										{businessProfiles === undefined
											? CapitalizeFirstLetter(businessProfileName)
											: CapitalizeFirstLetter(
													businessProfiles ? (businessProfiles.page_title ? businessProfiles.page_title : "") : "",
												)}
									</Typography>
								)}
							</TextContainer>
						</ServiceNameContainer>
					</CustomDiv>
				);
			case 1:
				return (
					<ServiceLocation
						dispatch={dispatch}
						newServiceDataLocationCoordinates={newServiceData?.search_metrics.location_coordinates}
						showMap={newServiceData ? (newServiceData.location.title.length > 0 ? true : false) : false}
					/>
				);
			case 2:
				return (
					<ServiceDetails
						newServiceData={newServiceData}
						dispatch={dispatch}
						serviceTypeError={serviceTypeError}
						serviceSubTypeError={serviceSubTypeError}
						serviceMinPriceError={serviceMinPriceError}
						serviceMaxPriceError={serviceMaxPriceError}
						setServiceTypeError={setServiceTypeError}
						setServiceSubTypeError={setServiceSubTypeError}
						setServiceMinPriceError={setServiceMinPriceError}
						setServiceMaxPriceError={setServiceMaxPriceError}
						serviceProviderNameError={serviceProviderNameError}
						setServiceProviderNameError={setServiceProviderNameError}
					/>
				);
			case 3:
				return (
					<UploadImages
						newServiceData={newServiceData}
						dispatch={dispatch}
						session={session}
						businessProfiles={businessProfiles}
						isBusinessProfile={true}
						isNewBusinessProfile={createBusinessProfile}
					/>
				);
			case 4:
				return (
					<ServiceCard
						loading={false}
						title={serviceTitle}
						images={imgPreview}
						location={newServiceData?.location.title}
						price={newServiceData?.price}
						serviceSubtype={newServiceData?.service_subtype}
						service_provider_name={newServiceData?.service_provider.name}
						show_contact_details={false}
						source={"search"}
					/>
				);
		}
	};

	return (
		<React.Fragment>
			{/* alert dialog */}
			<Snack
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={newServiceData?.snack_text === "Error while creating service" ? "error" : "success"}
					sx={{ width: "100%" }}
				>
					{newServiceData?.snack_text}
				</Alert>
			</Snack>
			<Dialog
				open={alertModal}
				maxWidth="sm"
				fullWidth={true}
				onClose={() => {
					setAlertModal(false);
				}}
			>
				<DialogTitle sx={{ fontSize: "1.25rem", fontWeight: 500, lineHeight: "160%" }}>
					Close service upload ?
				</DialogTitle>
				<DialogContent>
					<DialogContentText>All the unsaved data you entered will be removed</DialogContentText>
				</DialogContent>
				<DialogActions>
					<BeegruButton
						onClick={handleModalClose}
						color="primary"
						variant="outlined"
					>
						Yes
					</BeegruButton>
					<BeegruButton
						onClick={() => {
							setAlertModal(false);
						}}
						color="primary"
						variant="contained"
					>
						No
					</BeegruButton>
				</DialogActions>
			</Dialog>
			<CustomDialog
				open={open}
				maxWidth="sm"
				fullWidth={true}
				PaperProps={{
					style: {
						maxHeight: 700,
					},
				}}
			>
				<CustomDialogTitle>
					<TextContainer sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
						{!loading && createBusinessProfile && businessProfilesCount > 0 ? (
							<IconButton
								aria-label="back"
								color="primary"
								sx={{
									padding: "0rem 0.5rem 0rem 0rem",
								}}
								onClick={() => {
									setcreateBusinessProfile(false);
								}}
							>
								<ArrowBack />
							</IconButton>
						) : null}
						{activeStep === 0
							? "Create New Service"
							: activeStep === 1
								? "Service Location"
								: activeStep === 2
									? "Service Details"
									: activeStep === 3
										? "Upload Images"
										: "Service Preview "}

						<IconButton
							aria-label="close"
							onClick={() => {
								activeStep === 0 ? handleModalClose() : setAlertModal(true);
							}}
						>
							{loading ? null : <Close />}
						</IconButton>
					</TextContainer>
					<ColorLinearProgress
						variant="determinate"
						value={(activeStep / 4) * 100}
						sx={{ width: "100%" }}
					/>
				</CustomDialogTitle>

				<CustomDialogContent>
					{stepperContent(activeStep)}

					{activeStep === 2 ? (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								width: "100%",
							}}
						>
							<Typography
								sx={{
									fontSize: "0.875rem",
									fontWeight: 400,
									lineHeight: "150%",
									color: theme.palette.mode === "dark" ? "#FFFFFF" : "#9E9E9E",
								}}
							>
								Fields marked with * are mandatory
							</Typography>
						</div>
					) : null}

					<CustomDiv>
						{!loadNext ? (
							<TextContainer
								sx={{
									flexDirection: "row",
									width: "100%",
									justifyContent: "space-between",
									alignItems: "center",
									gap: "1rem",
								}}
							>
								{activeStep === 0 ? (
									<TextContainer sx={{ width: "8.75rem" }}></TextContainer>
								) : (
									<BeegruButton
										size="medium"
										flavor="primary"
										variant="text"
										onClick={handleBack}
										disabled={activeStep === 0 || loadNext}
										sx={{ width: "8.75rem" }}
									>
										Back
									</BeegruButton>
								)}
								<StepDiv>
									<CustomTypography>{activeStep + 1}/4</CustomTypography>
								</StepDiv>

								<BeegruButton
									size="medium"
									flavor="primary"
									variant="contained"
									onClick={activeStep === 4 ? handleClickCreateNewService : handleNext}
									disabled={stepOneComplete}
									// disabled={stepZeroComplete || stepOneComplete || stepTwoComplete || stepThreeCompelte}
									sx={{ width: "8.75rem" }}
								>
									{activeStep === 3 ? "Preview" : activeStep === 4 ? "Publish" : "Next"}
								</BeegruButton>
							</TextContainer>
						) : (
							<TextContainer sx={{ flexDirection: "row", width: "100%", justifyContent: "flex-end", gap: "1rem" }}>
								<Typography
									variant="body2"
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
										gap: "0.25rem",
										color: "primary.main",
									}}
								>
									Creating service<Circle>.</Circle>
									<Circle1>.</Circle1>
									<Circle2>.</Circle2>
								</Typography>
								<CircularProgress size={24} />
							</TextContainer>
						)}
					</CustomDiv>
				</CustomDialogContent>
			</CustomDialog>
		</React.Fragment>
	);
};

export default CreateServiceWithUploadTool;
