import { styled } from "@mui/system";
import React, { ReactNode } from "react";

/* Styled Container Component */

const ContainerComponent = styled("div")(({ theme }) => ({
	/* XXS breakpoint */
	[theme.breakpoints.only("xxs")]: {
		padding: "0rem 0.5rem 0rem 0.5rem",
	},
	/* XS breakpoint */
	[theme.breakpoints.only("xs")]: {
		padding: "0rem 0.75rem 0rem 0.75rem",
	},
	/* XSPLUS breakpoint */
	[theme.breakpoints.only("xsPlus")]: {
		padding: "0rem 1.5rem 0rem 1.5rem",
	},
	/* SM breakpoint */
	[theme.breakpoints.only("sm")]: {
		padding: "0rem 3rem 0rem 3rem",
	},
	/* SMPLUS breakpoint */
	[theme.breakpoints.only("smPlus")]: {
		padding: "0rem 3rem 0rem 3rem",
	},
	/* SM822 breakpoint */
	[theme.breakpoints.only("sm822")]: {
		padding: "0rem 3rem 0rem 3rem",
	},
	[theme.breakpoints.down("sm822")]: {
		padding: "0rem 1.5rem 0rem 1.5rem",
	},
	/* SM860 breakpoint */
	[theme.breakpoints.only("sm860")]: {
		padding: "0rem 3rem 0rem 3rem",
	},
	/* SM860 breakpoint */
	[theme.breakpoints.only("sm910")]: {
		padding: "0rem 3rem 0rem 3rem",
	},
	[theme.breakpoints.down(theme.breakpoints.values.sm910 + 5)]: {
		padding: "0rem 1.5rem 0rem 1.5rem",
	},
	/* MD breakpoint */
	[theme.breakpoints.only("md")]: {
		padding: "0rem 4rem 0rem 4rem",
	},
	/* MD1190 breakpoint */
	[theme.breakpoints.only("md1190")]: {
		padding: "0rem 4rem 0rem 4rem",
	},
	/* MD1220 breakpoint */
	[theme.breakpoints.only("md1220")]: {
		padding: "0rem 4rem 0rem 4rem",
	},
	/* LG breakpoint */
	[theme.breakpoints.only("lg")]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 4rem 0rem 4rem",
	},
	/* LGPLUS breakpoint */
	[theme.breakpoints.up("lgPlus")]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 5rem 0rem 5rem",
	},
	[theme.breakpoints.up(1500)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 10rem 0rem 10rem",
	},
	[theme.breakpoints.up(1640)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 15rem 0rem 15rem",
	},
	[theme.breakpoints.up(1780)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 20rem 0rem 20rem",
	},
	/* XL breakpoint */
	[theme.breakpoints.up("xl")]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 23rem 0rem 23rem",
	},
	[theme.breakpoints.up(2020)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 26rem 0rem 26rem",
	},
	[theme.breakpoints.up(2120)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 29rem 0rem 29rem",
	},
	[theme.breakpoints.up(2220)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 32rem 0rem 32rem",
	},
	[theme.breakpoints.up(2350)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 39rem 0rem 39rem",
	},
	/* XXL breakpoint */
	[theme.breakpoints.up("xxl")]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 36rem 0rem 36rem",
	},
	[theme.breakpoints.up(2800)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 39rem 0rem 39rem",
	},
	[theme.breakpoints.up(2900)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 40rem 0rem 40rem",
	},
	[theme.breakpoints.up(3000)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 42rem 0rem 42rem",
	},
	[theme.breakpoints.up(3100)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 47rem 0rem 47rem",
	},
	[theme.breakpoints.up(3200)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 52rem 0rem 52rem",
	},
	[theme.breakpoints.up(3300)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 57rem 0rem 57rem",
	},
	[theme.breakpoints.up(3400)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 62rem 0rem 62rem",
	},
	[theme.breakpoints.up(3500)]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 67rem 0rem 67rem",
	},
	/* XXXL breakpoint */
	[theme.breakpoints.up("xxxl")]: {
		// position: "absolute",
		// zIndex: 1,
		padding: "0rem 72rem 0rem 72rem",
	},
	// [theme.breakpoints.up(4500)]: {
	// 	// position: "absolute",
	// 	// zIndex: 1,
	// 	padding: "0rem 107rem 0rem 107rem",
	// },
}));

const ContainerComponentNoContain = styled("div")(({ theme }) => ({
	padding: "0rem 0rem 0rem 0rem",
}));

/* Let's extend React.ReactElement to accept an index prop */

interface ReactElementCustom extends React.ReactElement {
	index: number;
}

/* Default Export */

const Container = (props: {
	noContain: boolean;
	children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined | ReactNode;
}) => {
	return (
		<React.Fragment>
			{React.Children.map(props.children, (child: ReactElementCustom) => {
				if (React.isValidElement(child)) {
					return props.noContain
						? React.cloneElement(
								<ContainerComponentNoContain key={child.key ? child.key : 0}>{child}</ContainerComponentNoContain>,
								(() => ({
									"layout-child-index": child.props.index,
									"is-contained": "false",
								}))(),
							)
						: React.cloneElement(
								<ContainerComponent key={child.key ? child.key : 0}>{child}</ContainerComponent>,
								(() => ({
									"layout-child-index": child.props.index,
									"is-contained": "true",
								}))(),
							);
				}
				return child;
			})}
		</React.Fragment>
	);
};

export default Container;
