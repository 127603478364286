/* Common Imports */

import { styled } from "@mui/system";
import { ObjectId } from "mongodb";
import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import {
	createNewPropertyWithUploadToolThunk,
	setCreateNePropertyMinBalconiesThunk,
	setCreateNewePropertyPossessionDateThunk,
	setCreateNewPropertyAgeThunk,
	setCreateNewPropertyAvailabiltyThunk,
	setCreateNewPropertyDefaultLocationThunk,
	setCreateNewPropertyFloorNoThunk,
	setCreateNewPropertyFurnishingThunk,
	setCreateNewPropertyLandAreaThunk,
	setCreateNewPropertyLandAreaUnitThunk,
	setCreateNewPropertyLandFacingThunk,
	setCreateNewPropertyMaxBalconiesThunk,
	setCreateNewPropertyMaxBathroomsThunk,
	setCreateNewPropertyMaxBedroomsThunk,
	setCreateNewPropertyMaxCarpetAreaThunk,
	setCreateNewPropertyMaxFloorsThunk,
	setCreateNewPropertyMaxPriceThunk,
	setCreateNewPropertyMaxSuperBuiltUpAreaThunk,
	setCreateNewPropertyMinBathroomsThunk,
	setCreateNewPropertyMinBedroomsThunk,
	setCreateNewPropertyMinCarpetAreaThunk,
	setCreateNewPropertyMinPriceThunk,
	setCreateNewPropertyMinSuperBuiltUpAreaThunk,
	setCreateNewPropertyNoOfSeatsThunk,
	setCreateNewPropertyNoOfUnitsThunk,
	setCreateNewPropertyOverviewThunk,
	setCreateNewPropertyPlotDimensionsThunk,
	setCreateNewPropertySecurityDepositThunk,
	setCreateNewPropertySubTypeThunk,
	setCreateNewPropertySuperBuiltUpAreaUnitThunk,
	setCreateNewPropertyTransactionTypeThunk,
	setCreateNewPropertyTypeThunk,
	setCreateNewPropertyUnitFacingThunk,
	setCreateNewPropertyUploadImagesThunk,
	setPostPropertyWithUploadToolSnackbarErrorThunk,
	setProfileContextThunk,
} from "../../../redux-magic/store";
import { DbUserPage } from "../../../redux-magic/sub-interfaces/user-interface";
import location_data from "../../../redux-magic/sub-state-data/location-lat-lng";

/* Component Imports */

import PropertyCard from "../../cards/OFBCardPublishPreview/OFBCardPublishPreview/OFBCardPublishPreview";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import PropertyDetails from "./sub-components/CreatePropertySubComponents/PropertyDetails";
import PropertyLocation from "./sub-components/CreatePropertySubComponents/PropertyLocation";
import PropertyOverview from "./sub-components/CreatePropertySubComponents/PropertyOverview";
import UploadImages from "./sub-components/CreatePropertySubComponents/UploadImages";

/* mui imports */

import {
	Alert,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	LinearProgress,
	Link,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import Initials from "../../cards/Initials";

/* Icon Imports */

import { ArrowBack, Close, InfoOutlined, PersonAddAltOutlined } from "@mui/icons-material";

/* library imports */

import CapitalizeFirstLetter from "../../../lib/CapitalizeFirstLetter";
import { security_deposit } from "../../../lib/data/search-filters";
import { property_images } from "../../../redux-magic/sub-interfaces/new-property-data-interface";

/* Styled Components */

const Circle = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn": {
		"0%": {
			opacity: 0,
			// content: `${text[0]}`,
		},
		"25%": {
			opacity: 1,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn 2s infinite",
}));
const Circle1 = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn1": {
		"0%": {
			opacity: 0,
			// content: `${text[2]}`,
		},
		"50%": {
			opacity: 1,
			// content: `${text[0]}`,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn1 2s infinite",
}));
const Circle2 = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn2": {
		"0%": {
			opacity: 0,
			// content: `${text[0]}`,
		},

		"75%": {
			opacity: 1,
			// content: `${text[3]}`,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn2 2s infinite",
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
	padding: "2rem",

	[theme.breakpoints.down("sm")]: {
		padding: "1rem",
	},
	"& .MuiDialog-paper": {
		[theme.breakpoints.down("sm")]: {
			margin: "0rem",
			width: "100%",
		},
	},
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	fontSize: "1.25rem",
	fontWeight: 500,
	lineHeight: "106%",
	background: theme.palette.background.paper,
	gap: "1rem",
	padding: "1rem 2rem 0rem 2rem",
	borderRadius: "1rem 1rem 0rem 0rem",
	[theme.breakpoints.down("sm")]: {
		padding: "1rem ",
	},
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1.5rem",
	padding: "2rem 2rem 2rem 2rem",
	background: theme.palette.background.paper,
	borderRadius: "0rem 0rem 1rem 1rem",
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
		padding: "1rem ",
	},
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	padding: "0rem 1rem 1rem 1rem",
	background: theme.palette.background.paper,
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const ProfilePicture = styled("img")(({ theme }) => ({
	borderRadius: "8px",
	height: "2.75rem",
	width: "2.75rem",
}));

const NameTypeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: 400,
}));

const TypeTypo = styled(Typography)(({ theme }) => ({
	fontWeight: "400",
	fontSize: "0.875rem",
	color: theme.palette.mode == "dark" ? "#C6C6C6" : "#666666",
}));

const CustomDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.25rem",
}));

const ColorLinearProgress = styled(LinearProgress)(({ theme }) => ({
	"&.MuiLinearProgress-colorPrimary": {
		backgroundColor: "#E0E0E0",
	},
	"& .MuiLinearProgress-bar": {
		backgroundColor: theme.palette.primary.main,
	},
}));

const DotContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	gap: "0.5rem",
}));

const StepDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "2.5rem",
	background: theme.palette.mode == "dark" ? "#FFFFFF" : "var(--Text-New-Secondary, #666)",
	padding: "0rem 0.5rem",
	gap: "0.5rem",
	height: "1.5rem",
	width: "2.25rem",
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
	fontSize: "0.75rem",
	fontWeight: 400,
	color: theme.palette.mode == "dark" ? "#212121" : "#ffffff",
	padding: "0rem",
}));

const Snack = styled(Snackbar)(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		bottom: "5rem",
		borderRadius: "0.5rem",
	},
}));

/* Inrterface */

interface security_deposit {
	min: number;
	max: number;
	negotiable: boolean;
	currency: string;
}

const CreateNewOFBWithUploadTool = ({
	creator_user_id,
	business_profile_id,
	open,
	handleClose,
	dispatch,
	new_listing_url,
	profile_context,
	session,
	newPropertyData,
}: {
	creator_user_id: string | ObjectId;
	business_profile_id: string | ObjectId;
	open: boolean;
	handleClose: any;
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	profile_context: StateInterface["profile_context"];
	session: any;
	newPropertyData: StateInterface["new_property_data"];
}) => {
	// console.log("creator_user_id", creator_user_id);
	// console.log("profile_context", profile_context);

	const theme = useTheme();
	/* User business profiles */

	let businessProfiles: DbUserPage = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.pages
					? session.user.dbUser.pages[0]
					: []
				: []
			: []
		: [];

	const businessProfilesCount = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.business_profiles_count
					? session.user.dbUser.business_profiles_count
					: 0
				: 0
			: 0
		: 0;

	// console.log("businessProfileCount", businessProfilesCount);

	const [activeStep, setActiveStep] = React.useState(0);

	const [propertyTitle, setPropertyTitle] = React.useState<string>("");

	const [businessProfileName, setBusinessProfileName] = React.useState<string>("");

	const [businessProfileType, setBusinessProfileType] = React.useState<string>("");

	const [createBusinessProfile, setcreateBusinessProfile] = React.useState<boolean>(false);

	const [loading, setLoading] = React.useState(false);

	const [loadNext, setLoadNext] = React.useState<boolean>(false);

	const [alertModal, setAlertModal] = React.useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const [propertyNameError, setPropertyNameError] = React.useState<boolean>(false);

	const [businessProfileNameError, setBusinessProfileNameError] = React.useState<boolean>(false);

	const [businessProfileTypeError, setBusinessProfileTypeError] = React.useState<boolean>(false);

	const [transactionTypeError, setTransactionTypeError] = React.useState<boolean>(false);

	const [propertyCategoryError, setPropertyCategoryError] = React.useState<boolean>(false);

	const [propertyTypeError, setPropertyTypeError] = React.useState<boolean>(false);

	const [priceMinError, setPriceMinError] = React.useState<boolean>(false);

	const [priceMaxError, setPriceMaxError] = React.useState<boolean>(false);

	const [availabilityError, setAvailabilityError] = React.useState<boolean>(false);

	const [possessionDateError, setPossessionDateError] = React.useState<boolean>(false);

	/* Session user and name */

	const session_user = session ? (session.user ? session.user.dbUser : "") : "";

	const userName = session
		? session.user.dbUser.first_name !== ""
			? session.user.dbUser.first_name + " " + session.user.dbUser.last_name
			: session.user.dbUser.phone
		: "Beegru";

	/* User Profile picture */

	const profilePicture = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.profile_picture
					? (session.user.dbUser.profile_picture.exists as boolean)
						? session.user.dbUser.profile_picture.file_id
						: "/images/icons/Avatar.svg"
					: "/images/icons/Avatar.svg"
				: "/images/icons/Avatar.svg"
			: "/images/icons/Avatar.svg"
		: "/images/icons/Avatar.svg";

	// lets check if the 0th step is complete
	const stepZeroComplete: boolean =
		(activeStep != 0 && createBusinessProfile && (businessProfileName === "" || businessProfileType === "")) ||
		(businessProfiles === null && (businessProfileName === "" || businessProfileType === "")) ||
		propertyTitle === "" ||
		loading;

	// lets check if the 1st step is complete
	// First lets extract current location values
	const currentLatitude = newPropertyData?.search_metrics.location_coordinates[0];
	const currentLongitude = newPropertyData?.search_metrics.location_coordinates[1];

	// Next, lets extract previous location values (assuming location_data is available in scope)
	const previousLatitude = location_data.location[0];
	const previousLongitude = location_data.location[1];

	//Next, lets Check if location value has changed
	const didLocationChange = currentLatitude !== previousLatitude || currentLongitude !== previousLongitude;

	const stepOneComplete: boolean = activeStep === 1 && !didLocationChange;

	const [newListing, setNewListing] = React.useState<boolean>(false);

	// Finally, lets check if stepOne is complete

	// lets check if the 4th step is complete

	const stepThreeComplete: boolean = activeStep === 3 && newPropertyData?.description === "";

	// lets check if the 4th step is complete

	const stepFourComplete: boolean = activeStep === 4 && newPropertyData?.images.length === 0;

	// lets create functions to handle the stepper
	const handleNext = () => {
		if (activeStep === 0) {
			if (
				(createBusinessProfile && (businessProfileName === "" || businessProfileType === "")) ||
				(businessProfiles === null && (businessProfileName === "" || businessProfileType === "")) ||
				propertyTitle === ""
			) {
				if (propertyTitle === "") {
					setPropertyNameError(true);
				}
				if (createBusinessProfile && businessProfileName === "") {
					setBusinessProfileNameError(true);
				}
				if (createBusinessProfile && businessProfileType === "") {
					setBusinessProfileTypeError(true);
				}
			} else {
				setPropertyNameError(false);
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 1) {
			if (didLocationChange) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 2) {
			if (
				newPropertyData?.property_category === "" ||
				(newPropertyData.transaction_type !== "" &&
					newPropertyData.property_category !== "" &&
					newPropertyData.property_category !== "public infrastructure" &&
					newPropertyData.property_category !== "joint development" &&
					newPropertyData.property_category !== "joint venture" &&
					newPropertyData.property_category !== "warehousing")
			) {
				if (newPropertyData?.property_category === "") {
					setPropertyCategoryError(true);
				}
				if (newPropertyData?.transaction_type === "") {
					setTransactionTypeError(true);
				}
				if (newPropertyData?.property_type?.length === 0) {
					setPropertyTypeError(true);
				}
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 3) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	/* Change profile context function */

	const onChangeProfileContext = async (id: ObjectId | string) => {
		if (id) {
			setLoading(true);
			await dispatch(
				setProfileContextThunk({
					is_business_profile: id === session_user._id ? false : true,
					user_id: session_user ? session_user._id : "",
					business_profile_id: id,
					page_type:
						id === session_user._id ? "user" : businessProfiles?.page_id === id ? businessProfiles?.page_type : "",
					// businessProfiles?.find((item: any) => item.page_id === id).page_type
					user_kyc_status: session.user.dbUser.kyc_info.kyc_status,
					user_subscription_information: session.user.dbUser.subscription_information,
					remember_choice: true,
				}),
			);
			setLoading(false);
		}
	};

	const propertyImageObject = newPropertyData?.images.map((image: property_images) => {
		return {
			file_id: image.file_id,
			mime_type: image.mime_type,
			category: image.category,
			caption: image.caption,
			priority: image.priority,
		};
	});

	const handleClickCreateNewProperty = async () => {
		setLoading(true);
		setLoadNext(true);
		setNewListing(true);
		await dispatch(
			createNewPropertyWithUploadToolThunk({
				createNewBusinessProfile: createBusinessProfile,
				businessProfileId: createBusinessProfile ? "" : businessProfiles?.page_id,
				creatorIsBusinessProfile: createBusinessProfile ? true : profile_context?.is_business_profile,
				businessProfileName: createBusinessProfile ? businessProfileName : businessProfiles?.page_title,
				businessProfileType: createBusinessProfile ? businessProfileType.toLowerCase() : businessProfiles?.page_type,
				userId: session.user.dbUser._id,
				phoneNumber: session.user.dbUser.phone,
				countryCode: session.user.dbUser.country_code,
				propertyName: propertyTitle,
				propertyLocationName: newPropertyData?.location.title,
				propertyLocationCoordinates: newPropertyData?.search_metrics.location_coordinates,
				propertyTransactionType: newPropertyData?.transaction_type,
				propertyCategory: newPropertyData?.property_category,
				propertyType: newPropertyData?.property_type,
				propertySubtype: newPropertyData?.property_subtype,
				propertyMinPrice: newPropertyData?.price.min,
				propertyMaxPrice: newPropertyData?.price.max,
				propertySecurityDeposit: newPropertyData?.security_deposit.max,
				propertyStatus: newPropertyData?.availability[0],
				propertyPossessionDate: newPropertyData?.possession_date,
				propertyAge: newPropertyData?.property_age,
				propertyFacing: newPropertyData?.unit_facing,
				propertyFurnishing: newPropertyData?.furnished,
				propertyMinBedrooms: newPropertyData?.bhk.min,
				propertyMaxBedrooms: newPropertyData?.bhk.max,
				propertyMinBathrooms: newPropertyData?.bathroom.min,
				propertyMaxBathroom: newPropertyData?.bathroom.max,
				propertyNoOfBalconies: newPropertyData?.balconies.max,
				propertyNoOfSeats: newPropertyData?.no_of_seats.max,
				propertyNoOfUnits: newPropertyData?.no_of_units.max,
				propertyNoOfFloors: newPropertyData?.floors.max,
				propertyPlotDimensions: newPropertyData?.plot_dimensions,
				propertyLandAreaUnit: newPropertyData?.area.land_area.land_area_unit,
				propertyLandArea: newPropertyData?.area.land_area.max.acre,
				propertySuperBuiltUpAreaUnit: newPropertyData?.area.super_built_up_area.super_built_up_area_unit,
				propertyMinSuperBuiltUpArea: newPropertyData?.area.super_built_up_area.min.ft,
				propertyMaxSuperBuiltUpArea: newPropertyData?.area.super_built_up_area.max.ft,
				propertyIsCarpetAreaPercentage: true,
				propertyCarpetAreaPercentage: "0",
				propertyCarpetAreaUnit: newPropertyData?.area.carpet_area.carpet_area_unit,
				propertyMinCarpetArea: newPropertyData?.area.carpet_area.min.ft,
				propertyMaxCarpetArea: newPropertyData?.area.carpet_area.max.ft,
				propertyImages: propertyImageObject,
				propertyOverview: newPropertyData?.description,
			}),
		);
	};

	// Lets check if the  profile_context is_business_profile is true

	const isBusinessProfile: boolean = profile_context ? profile_context.is_business_profile : false;

	// Lets fetch the profile_context user_id

	const profileContextUserId: string | ObjectId = profile_context ? profile_context.user_id : "";

	// Lets fetch the profile_context page_slug

	const profileContextPageSlug: string = profile_context ? profile_context.page_slug : "";

	// Lets fetch the current page

	const currentPage: String = newPropertyData ? newPropertyData.currentPage : "";

	// Lets check if the OFB creator is on his page

	const userCreatorIsOnHisPage: boolean = currentPage === profileContextUserId && !isBusinessProfile ? true : false;

	const businessCreatorOnHisPage: boolean = currentPage === profileContextPageSlug && isBusinessProfile ? true : false;

	React.useEffect(() => {
		const businessType: string = profile_context
			? profile_context.is_business_profile
				? profile_context.page_type
				: "user"
			: "user";

		if (
			new_listing_url !== undefined &&
			new_listing_url !== "" &&
			newListing &&
			!(businessCreatorOnHisPage || userCreatorIsOnHisPage)
		) {
			handleModalClose();
			window.open(`/${businessType}s/${new_listing_url}`, "_self");
		} else if (
			new_listing_url !== undefined &&
			new_listing_url !== "" &&
			newListing &&
			(businessCreatorOnHisPage || userCreatorIsOnHisPage)
		) {
			handleModalClose();
		} else {
			setPostPropertyWithUploadToolSnackbarErrorThunk(true);
			handleModalClose();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [new_listing_url]);

	React.useEffect(() => {
		if (newPropertyData?.snack_ofb_value) {
			setOpenSnackbar(true);
		}
	}, [newPropertyData?.snack_ofb_value]);

	const handleModalClose = () => {
		setcreateBusinessProfile(false);
		setBusinessProfileName("");
		setBusinessProfileType("");
		setPropertyTitle("");
		setLoading(false);
		setLoadNext(false);
		handleClose();
		setActiveStep(0);
		dispatch(
			setCreateNewPropertyDefaultLocationThunk({
				location_coordinates: location_data.location,
				address: "",
			}),
		);
		dispatch(setCreateNewPropertyTransactionTypeThunk(""));
		dispatch(setCreateNewPropertyTypeThunk([""]));
		dispatch(setCreateNewPropertySubTypeThunk(""));
		dispatch(setCreateNewPropertyMinPriceThunk(0));
		dispatch(setCreateNewPropertyMaxPriceThunk(0));
		dispatch(setCreateNewPropertySecurityDepositThunk(0));
		dispatch(setCreateNewPropertyAvailabiltyThunk([""]));
		dispatch(setCreateNewePropertyPossessionDateThunk(""));
		dispatch(setCreateNewPropertyUnitFacingThunk([""]));
		dispatch(setCreateNewPropertyLandFacingThunk([""]));
		dispatch(setCreateNewPropertyAgeThunk(0));
		dispatch(setCreateNewPropertyPlotDimensionsThunk([]));
		dispatch(setCreateNewPropertyFurnishingThunk([""]));
		dispatch(setCreateNewPropertyLandAreaThunk(0));
		dispatch(setCreateNewPropertyLandAreaUnitThunk(""));
		dispatch(setCreateNewPropertyMaxBalconiesThunk(0));
		dispatch(setCreateNewPropertyMaxBathroomsThunk(0));
		dispatch(setCreateNewPropertyMaxBedroomsThunk(0));
		dispatch(setCreateNewPropertyMaxCarpetAreaThunk(0));
		dispatch(setCreateNewPropertyMaxFloorsThunk(0));
		dispatch(setCreateNewPropertyMaxSuperBuiltUpAreaThunk(0));
		dispatch(setCreateNePropertyMinBalconiesThunk(0));
		dispatch(setCreateNewPropertyMinBathroomsThunk(0));
		dispatch(setCreateNewPropertyMinBedroomsThunk(0));
		dispatch(setCreateNewPropertyMinCarpetAreaThunk(0));
		dispatch(setCreateNewPropertyMinSuperBuiltUpAreaThunk(0));
		dispatch(setCreateNewPropertyNoOfUnitsThunk(0));
		dispatch(setCreateNewPropertyNoOfSeatsThunk(0));
		dispatch(setCreateNewPropertyFloorNoThunk(0));
		dispatch(setCreateNewPropertySuperBuiltUpAreaUnitThunk(""));
		dispatch(setCreateNewPropertyOverviewThunk(""));
		dispatch(setCreateNewPropertyUploadImagesThunk([]));
		setAlertModal(false);
		setBusinessProfileNameError(false);
		setBusinessProfileTypeError(false);
		setTransactionTypeError(false);
		setPropertyNameError(false);
		setPropertyCategoryError(false);
		setPropertyTypeError(false);
	};

	const stepContent = (step: number) => {
		switch (step) {
			case 0:
				return (
					<CustomDiv sx={{ flexDirection: "column", paddingTop: "2rem", gap: "1rem" }}>
						{/* Profile Select */}

						{createBusinessProfile ? null : (
							<FormControl
								variant="outlined"
								fullWidth
							>
								<InputLabel
									id="select-profile"
									sx={{ padding: "0rem" }}
								>
									Posting as
								</InputLabel>
								<Select
									labelId="select-profile"
									label="Posting ass"
									disabled={loading}
									MenuProps={{
										disableScrollLock: true,
										MenuListProps: {
											disablePadding: true,
										},
										PaperProps: {
											sx: {
												background: theme.palette.background.paper,
												maxHeight: "20rem",
												borderRadius: "16px",
											},
										},
									}}
									value={
										profile_context
											? profile_context.is_business_profile
												? profile_context.business_profile_id
													? (profile_context.business_profile_id as string)
													: ""
												: profile_context.user_id
													? (profile_context.user_id as string)
													: ""
											: ""
									}
									onChange={(e) => {
										onChangeProfileContext(e.target.value);
									}}
									size="small"
									sx={{
										// marginBottom: "0.5rem",
										minWidth: "20%",
										"& .MuiSelect-select": {
											display: "flex",
											flexDirection: "row",
											justifyContent: "flex-start",
											alignItems: "center",
											gap: "0.5rem",
										},
										"& .MuiPaper": {
											background: theme.palette.background.paper,
										},
									}}
								>
									<CustomMenuItem value={session_user._id}>
										{!profilePicture.includes("/images/icons/Avatar.svg") ? (
											<ProfilePicture
												src={`/profile-picture/${profilePicture}`}
												alt={session ? session.user.dbUser.first_name.charAt(0).toUpperCase() : null}
												referrerPolicy="no-referrer"
												loading="lazy"
											/>
										) : (
											<Initials
												title={session ? session.user.dbUser.first_name + " " + session.user.dbUser.last_name : ""}
												profiledropdown={false}
												header={false}
												comment={false}
												postcard={false}
												businessprofile={false}
												selectProfile={true}
											/>
										)}
										<NameTypeContainer>
											<Heading sx={{ width: "100%" }}>{CapitalizeFirstLetter(userName)}</Heading>
											<TypeTypo>User</TypeTypo>
										</NameTypeContainer>
									</CustomMenuItem>

									{businessProfilesCount > 0 && (
										<CustomMenuItem value={businessProfiles?.page_id as string}>
											{businessProfiles?.display_picture_url?.exists ? (
												<ProfilePicture
													src={`/profile-picture/${businessProfiles?.display_picture_url?.file_id}`}
													alt={businessProfiles?.page_title.toUpperCase()}
													referrerPolicy="no-referrer"
													loading="lazy"
												/>
											) : (
												<Initials
													title={businessProfiles?.page_title}
													profiledropdown={false}
													header={false}
													comment={false}
													postcard={false}
													businessprofile={false}
													selectProfile={true}
												/>
											)}
											<NameTypeContainer>
												<Heading>
													{businessProfiles?.page_title?.length > 15
														? CapitalizeFirstLetter(businessProfiles?.page_title)?.slice(0, 15) + "..."
														: CapitalizeFirstLetter(businessProfiles?.page_title)}
												</Heading>
												<TypeTypo>{CapitalizeFirstLetter(businessProfiles?.page_type)}</TypeTypo>
											</NameTypeContainer>
										</CustomMenuItem>
									)}

									{businessProfilesCount === 0 && (
										<CustomMenuItem
											onClick={() => {
												setcreateBusinessProfile(true);
											}}
										>
											<PersonAddAltOutlined
												color="primary"
												sx={{
													margin: "0rem 0.75rem 0.25rem 0.65rem",
													fontSize: "1.5rem",
												}}
											/>
											<Typography
												variant="body1"
												sx={{
													color: theme.palette.mode == "dark" ? "#ffffff" : "#000000",
												}}
											>
												Add business profile
											</Typography>
										</CustomMenuItem>
									)}
								</Select>
							</FormControl>
						)}

						{createBusinessProfile ? (
							<>
								<Typography variant="body2">
									Let&apos;s create a business profile to post your OFB.{" "}
									<Link
										href="/faqs"
										rel="noopener"
										target="_self"
										referrerPolicy="no-referrer"
										sx={{
											textDecoration: "none",
											display: "contents",
											"&:hover": {
												textDecoration: "underline",
											},
										}}
									>
										Learn more
									</Link>
								</Typography>

								<TextField
									label="Business Name*"
									required={true}
									disabled={loading}
									value={businessProfileName}
									autoComplete="off"
									variant="outlined"
									onChange={(event: any) => {
										setBusinessProfileName(event.target.value);
										setBusinessProfileNameError(false);
									}}
									sx={{ width: "100%", marginTop: "1rem" }}
									error={businessProfileNameError}
									helperText={businessProfileNameError ? "Please enter a name" : ""}
								/>
								<FormControl
									variant="outlined"
									fullWidth
									sx={{ marginTop: "1rem" }}
									error={businessProfileTypeError}
								>
									<InputLabel
										id="profile-type"
										sx={{ padding: "0rem" }}
									>
										Business profile type *
									</InputLabel>
									<Select
										labelId="profile-type"
										label="Business profile type *"
										required={true}
										disabled={loading}
										value={businessProfileType}
										sx={{ width: "100%" }}
										onChange={(e) => {
											setBusinessProfileType(e.target.value);
										}}
									>
										<MenuItem value="agent">Agent</MenuItem>
										<MenuItem value="developer">Developer</MenuItem>
										<MenuItem value="landowner">Landowner</MenuItem>
										<MenuItem value="professional">Professional</MenuItem>
									</Select>
									<FormHelperText>{businessProfileTypeError ? "Please select a type" : ""}</FormHelperText>
								</FormControl>
							</>
						) : null}
						<TextContainer sx={{ gap: "1rem" }}>
							<TextContainer sx={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
								<Typography sx={{ fontSize: "1rem", fontWeight: 400, lineHeight: "150%" }}>
									Enter the name of your ofb
								</Typography>
								<Tooltip title="	Once created, you cannot change the name">
									<InfoOutlined
										sx={{
											color: theme.palette.mode == "dark" ? "#FFFFFF" : "#0000008A",
											height: "1.25rem",
											width: "1.25rem",
										}}
									/>
								</Tooltip>
							</TextContainer>
							<TextField
								label="OFB Name*"
								placeholder="Enter OFB name"
								required={true}
								disabled={loading}
								value={propertyTitle}
								autoComplete="off"
								variant="outlined"
								onChange={(event: any) => {
									setPropertyTitle(event.target.value);
									setPropertyNameError(false);
								}}
								sx={{ width: "100%" }}
								error={propertyNameError}
								helperText={propertyNameError ? "Please enter a name" : ""}
							/>
						</TextContainer>
						{createBusinessProfile ? (
							<Typography
								variant="caption"
								sx={{ margin: "0.5rem 0rem 0rem 0rem" }}
							>
								* This OFB will be listed under your new business profile
							</Typography>
						) : null}
					</CustomDiv>
				);
			case 1:
				return (
					<PropertyLocation
						dispatch={dispatch}
						newPropertyDataLocationCoordinates={newPropertyData?.search_metrics.location_coordinates}
						showMap={newPropertyData ? (newPropertyData.location.title.length > 0 ? true : false) : false}
					/>
				);
			case 2:
				return (
					<PropertyDetails
						property_transaction_type={newPropertyData?.transaction_type}
						property_category_type={newPropertyData?.property_category}
						property_type={newPropertyData?.property_type}
						property_subtype={newPropertyData?.property_subtype}
						property_min_price={newPropertyData?.price?.min}
						property_max_price={newPropertyData?.price?.max}
						property_security_deposit={newPropertyData?.security_deposit as security_deposit}
						property_availability={newPropertyData?.availability}
						property_possession_date={newPropertyData?.possession_date}
						property_unit_facing={
							newPropertyData?.unit_facing
								? newPropertyData?.unit_facing.map(
										(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
									)
								: []
						}
						property_land_facing={
							newPropertyData?.land_facing
								? newPropertyData?.land_facing.map(
										(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
									)
								: []
						}
						property_age={newPropertyData?.property_age}
						dispatch={dispatch}
						transactionTypeError={transactionTypeError}
						setTransactionTypeError={setTransactionTypeError}
						propertyCategoryError={propertyCategoryError}
						setPropertyCategoryError={setPropertyCategoryError}
						propertyTypeError={propertyTypeError}
						setPropertyTypeError={setPropertyTypeError}
						priceMinError={priceMinError}
						setPriceMinError={setPriceMinError}
						priceMaxError={priceMaxError}
						setPriceMaxError={setPriceMaxError}
						availabilityError={availabilityError}
						setAvailabilityError={setAvailabilityError}
						possessionDateError={possessionDateError}
						setPossessionDateError={setPossessionDateError}
					/>
				);
			case 3:
				return (
					<PropertyOverview
						overview_data={newPropertyData?.description}
						dispatch={dispatch}
					/>
				);
			case 4:
				return (
					<UploadImages
						newPropertyData={newPropertyData}
						dispatch={dispatch}
						session={session}
						businessProfiles={businessProfiles}
						isBusinessProfile={profile_context?.is_business_profile}
						isNewBusinessProfile={createBusinessProfile}
					/>
				);
			case 5:
				return (
					<PropertyCard
						images={newPropertyData ? (newPropertyData.images.length > 0 ? newPropertyData.images[0].caption : "") : ""}
						title={propertyTitle}
						property_status={newPropertyData?.availability}
						transaction_type={newPropertyData?.transaction_type}
						price={newPropertyData?.price}
						property_type={newPropertyData?.property_type}
						location={newPropertyData?.location.title}
						property_category={newPropertyData?.property_category}
					/>
				);
		}
	};

	return (
		<React.Fragment>
			<Snack
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={newPropertyData?.snack_text === "Error while creating OFB" ? "error" : "success"}
					sx={{ width: "100%" }}
				>
					{newPropertyData?.snack_text}
				</Alert>
			</Snack>
			<Dialog
				open={alertModal}
				maxWidth="sm"
				fullWidth={true}
				onClose={() => {
					setAlertModal(false);
				}}
			>
				<DialogTitle sx={{ fontSize: "1.25rem", fontWeight: 500, lineHeight: "160%" }}>Close OFB upload ?</DialogTitle>
				<DialogContent>
					<DialogContentText>All the unsaved data will be removed. Are you sure ?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<BeegruButton
						onClick={handleModalClose}
						color="primary"
						variant="outlined"
					>
						Yes
					</BeegruButton>
					<BeegruButton
						onClick={() => {
							setAlertModal(false);
						}}
						color="primary"
						variant="contained"
					>
						No
					</BeegruButton>
				</DialogActions>
			</Dialog>
			<CustomDialog
				open={open}
				maxWidth="sm"
				fullWidth={true}
				PaperProps={{
					style: {
						maxHeight: 700,
					},
				}}
			>
				<CustomDialogTitle>
					<TextContainer sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
						{!loading && createBusinessProfile && businessProfilesCount > 0 ? (
							<IconButton
								aria-label="back"
								color="primary"
								sx={{
									padding: "0rem 0.5rem 0rem 0rem",
								}}
								onClick={() => {
									setcreateBusinessProfile(false);
								}}
							>
								<ArrowBack />
							</IconButton>
						) : null}
						{activeStep === 0
							? "Create New OFB"
							: activeStep === 1
								? "Location"
								: activeStep === 2
									? "Details"
									: activeStep === 3
										? "Add Overview"
										: activeStep === 4
											? "Upload Images"
											: "Preview"}
						<IconButton
							aria-label="close"
							onClick={() => {
								activeStep === 0 ? handleModalClose() : setAlertModal(true);
							}}
						>
							{loading ? null : <Close />}
						</IconButton>
					</TextContainer>
					<ColorLinearProgress
						variant="determinate"
						value={(activeStep / 5) * 100}
						sx={{ width: "100%" }}
					/>
				</CustomDialogTitle>

				<CustomDialogContent>
					{stepContent(activeStep)}
					<CustomDiv>
						{!loadNext ? (
							<TextContainer
								sx={{
									flexDirection: "row",
									width: "100%",
									justifyContent: "space-between",
									alignItems: "center",
									gap: "1rem",
								}}
							>
								{activeStep === 0 ? (
									<TextContainer sx={{ width: "8.75rem" }}></TextContainer>
								) : (
									<BeegruButton
										size="medium"
										flavor="primary"
										variant="text"
										onClick={handleBack}
										disabled={activeStep === 0 || loadNext}
										sx={{ width: "8.75rem" }}
									>
										Back
									</BeegruButton>
								)}

								<StepDiv>
									<CustomTypography>{activeStep + 1}/6</CustomTypography>
								</StepDiv>

								<BeegruButton
									size="medium"
									flavor="primary"
									variant="contained"
									onClick={activeStep === 5 ? handleClickCreateNewProperty : handleNext}
									disabled={stepOneComplete}
									// disabled={
									// 	stepZeroComplete || stepOneComplete
									// 	// 	stepFiveComplete
									// }
									sx={{ width: "8.75rem" }}
								>
									{activeStep === 4 ? "Preview" : activeStep === 5 ? "Publish" : "Next"}
								</BeegruButton>
							</TextContainer>
						) : (
							<TextContainer sx={{ flexDirection: "row", width: "100%", justifyContent: "flex-end", gap: "1rem" }}>
								<Typography
									variant="body2"
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
										gap: "0.25rem",
										color: "primary.main",
									}}
								>
									Creating OFB<Circle>.</Circle>
									<Circle1>.</Circle1>
									<Circle2>.</Circle2>
								</Typography>
								<CircularProgress size={24} />
							</TextContainer>
						)}
					</CustomDiv>
				</CustomDialogContent>
			</CustomDialog>
		</React.Fragment>
	);
};

export default CreateNewOFBWithUploadTool;
